import React from 'react';

import Box from '@mui/material/Box';
import EmailIcon from '@mui/icons-material/Email';
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

function handleSubscribe() {
  document.location.href = "/posts";
}

export default function WhatsNew(props) {
  return <>
    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'beginning', alignItems: 'center', paddingTop: '5px', paddingBottom: '5px', paddingLeft: '20px', paddingRight: '20px', borderBottom: '1px solid #EEEEEE' }}>
      <CircleNotificationsIcon sx={{ color: 'red', marginLeft: '10px'}}/>
      <Typography sx={{ marginLeft: '5px' }}>
        Read our latest post,
        <Link sx={{ paddingLeft: '5px', paddingRight: '5px' }} href="/posts/celebrity-names-in-annapolis-county/" underline="none">"Celebrity" Names in Annapolis County</Link>, and subscribe for email updates!
      </Typography>
      <IconButton sx={{ padding: '5px', marginLeft: '5px' }} onClick={handleSubscribe}>
        <EmailIcon color="primary"/>
      </IconButton>
    </Box>
  </>
}
